.App {
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: black;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loader span {
    margin-top: 15px;
    color: white;
    text-align: center;
    font-size: 25px;
}

.start_button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 950px;
    height: 90%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: none;
    background-color: transparent;
}

.play_button {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);
    z-index: 10;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.play_button img {
    width: 140px;
    height: 80px;
    animation: dancingButton 2s infinite alternate;
}

.App canvas {
    width: 100% !important;
    height: 100% !important;
}

.dots-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dot {
    position: absolute;
    border-radius: 50%;
    opacity: 0.8;
    transition: transform 0.2s ease, all 1s ease;
    animation: dancingDots 0.25s infinite alternate;
    box-shadow: 0px 0px 10px rgb(246, 246, 246);
}

/* .dot::after {
    content: url('./assets/snowflake.svg');
    position: absolute;
    top: 0;
    left: 0;
    width: 5px !important;
    height: 5px !important;
} */


@keyframes dancingDots {
    0% {
        transform: scale(1.5)
    }

    100% {
        transform: scale(0.8)
    }
}

@keyframes dancingDotsMob {
    0% {
        transform: scale(1.1)
    }

    100% {
        transform: scale(0.5)
    }
}

@keyframes dancingButton {
    0% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(-5px)
    }
}

@media (min-width: 1024px) and (max-height: 700px) {
    .play_button {
        top: 83%;
        transform: translate(-50%, -83%);
    }
}

@media (max-width: 705px) {
    .start_button {
        width: 70%;
        height: 40%;
    }

    .play_button {
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -75%);
        z-index: 10;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    .play_button img {
        width: 100px;
        height: 60px;
        animation: dancingButton 2s infinite alternate;
    }

    .dot {
        position: absolute;
        border-radius: 50%;
        opacity: 0.8;
        transition: transform 0.2s ease, all 1s ease;
        animation: dancingDotsMob 0.25s infinite alternate;
        box-shadow: 0px 0px 10px rgb(246, 246, 246);
    }
}

@media (max-width: 600px) {
    .start_button {
        width: 90%;
        height: 40%;
    }
}